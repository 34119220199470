<template>
     <v-flex lg12>
         <v-layout row wrap>
            <v-flex lg2 class="hidden-md-and-down">
        <v-toolbar color="primary" dark>FOTO / FIRMA</v-toolbar>
        <FotoFirma />
        <br />
        <Resumen />
      </v-flex>
      <v-flex lg10>
        <v-tabs
          v-model="tab"
           background-color="primary"
          dark
          icons-and-text
          class="fixed-tabs-bar"
          color="red"
          centered
        >
        <v-tabs-slider color="orange"></v-tabs-slider>
          <v-tab :to="{name:'DefensorRegistro'}" key="tab-defensor-registro">
            REGISTRO RUANNA
            <v-icon>mdi-file-document</v-icon>
          </v-tab>
           <v-tab :to="{name:'DefensorBuzon'}">
          BANDEJA DE CASOS
          <v-icon>mdi-email</v-icon>
        </v-tab>
          <v-tab :to="{name:'DefensorFormacion'}">
            FORMACIÓN
            <v-icon>mdi-school</v-icon>
          </v-tab>
          <v-tab :to="{name:'DefensorExperiencia'}">
            EXPERIENCIA
            <v-icon>mdi-briefcase</v-icon>
          </v-tab>
           <v-tab :to="{name:'DefensorCausas'}">
          CAUSAS
          <v-icon>mdi-content-copy</v-icon>
        </v-tab>
        </v-tabs>
         <v-slide-x-transition>
        <router-view></router-view>
        </v-slide-x-transition>
      </v-flex>
         </v-layout>
     </v-flex>
</template>
<script>
export default {
  components: {
    FotoFirma: () => import("@/components/FotoFirma"),
    Resumen: () => import("@/components/Resumen")
  },
     computed: {
    fotoAbogado() {
      return this.$store.getters.getFotoAbogado;
    },
    firmaAbogado() {
      return this.$store.getters.getFirmaAbogado;
    }
  },
  data(){
    return{
      tab:'/ruanna/defensor',
    }
  }
}
</script>
<style>
.rpa-tab-active {
  color: red !important;
}
.v-tabs.fixed-tabs-bar.v-tabs--centered.v-tabs--icons-and-text.theme--dark {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 60px;
  z-index: 2;
}
.theme--light.v-input--is-disabled input {
  color: black !important;
}
.theme--light.v-select .v-chip--disabled,
.theme--light.v-select.v-input--is-disabled .v-select__selections,
.theme--light.v-select .v-select__selection--disabled {
  color: black !important;
}
.theme--light.v-input--is-disabled input {
  color: black !important;
}
.theme--light.v-textarea.v-input--is-disabled {
  color: black !important;
}
.tab-transparent {
  background-color: transparent !important;
  border-color: transparent !important;
}
</style>